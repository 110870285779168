import React from "react";

import styled from "@emotion/styled";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import Paragraph from "@narative/gatsby-theme-novela/src/components/Paragraph";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

function ToS() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          
          <Paragraph>
            <Headings.h1>Términos y Condiciones</Headings.h1>
            <HoritzontalRule/>
            <Headings.h3>Uso</Headings.h3>
            <p>Los artículos técnicos aquí mostrados son de ayuda principalmente personal que puede ser de utilidad para otros también.</p>

            <HoritzontalRule/>
            <Headings.h3>Trackers</Headings.h3>
            <p>Esta página contiene trackers de Google Analytics, AdSense y Google Tag Manager los cuales se rigen bajo los términos y condiciones establecidos por Google para cada plataforma.</p>
            
            <HoritzontalRule/>
            <Headings.h3>Facebook</Headings.h3>
            <p>Se usa Facebook Pixel para obtener estádisticas de audiencias y mejorar los ADs en dicha plataforma.</p>
            <p>Se usa la caja de comentarios de Facebook para la administración y moderación de los mismos asociados a la página <a href="https://www.facebook.com/FonsecaJ89/">Javier Fonseca</a>.</p>

            <HoritzontalRule/>
            <Headings.h3>Monetización</Headings.h3>
            <p>Esta página utilizará monetización mediante Google AdSense, contenido en artículos referentes a Affiliated Marketing y solicitud expresa de colaboración mediante PayPal.</p>
          </Paragraph>
        </div>
      </Section>
    </Layout>
  );
}

export default ToS;

const HoritzontalRule = styled.div`
  position: relative;
  margin: 40px auto 50px;
  border-bottom: 1px solid ${p => p.theme.colors.horizontalRule};
  ${mediaqueries.tablet`
    margin: 60px auto;
  `}
  ${mediaqueries.phablet`
    display: none;
  `}
`;